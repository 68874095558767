import(/* webpackMode: "eager" */ "/home/kel/scamsg/scam.sg/app/global.css");
import(/* webpackMode: "eager" */ "/home/kel/scamsg/scam.sg/components/Layouts/DefaultLayout.tsx");
import(/* webpackMode: "eager" */ "/home/kel/scamsg/scam.sg/node_modules/@clerk/nextjs/dist/esm/app-router/client/ClerkProvider.js");
import(/* webpackMode: "eager" */ "/home/kel/scamsg/scam.sg/node_modules/@clerk/nextjs/dist/esm/client-boundary/controlComponents.js");
import(/* webpackMode: "eager" */ "/home/kel/scamsg/scam.sg/node_modules/@clerk/nextjs/dist/esm/client-boundary/hooks.js");
import(/* webpackMode: "eager" */ "/home/kel/scamsg/scam.sg/node_modules/@clerk/nextjs/dist/esm/client-boundary/uiComponents.js");
import(/* webpackMode: "eager" */ "/home/kel/scamsg/scam.sg/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/home/kel/scamsg/scam.sg/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/home/kel/scamsg/scam.sg/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/home/kel/scamsg/scam.sg/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/home/kel/scamsg/scam.sg/provider/providers.tsx")